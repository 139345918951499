<template>
  <nuxt-link
    to="/sale"
    class="bf-banner"
  >
    <img
      src="/v2/global/black-friday24/bg.png"
      alt="bg"
      class="bf-banner__bg"
    >

    <img
      src="/v2/global/black-friday24/bg-tablet.png"
      alt="bg"
      class="bf-banner__bg--tablet"
    >

    <img
      src="/v2/global/black-friday24/bg-mobile.png"
      alt="bg"
      class="bf-banner__bg--mobile"
    >

    <div class="bf-banner__content">
      <img
        src="/v2/global/black-friday24/percent.png"
        alt="percent"
        class="bf-banner__percent"
      >

      <div class="bf-banner__text">
        <h5>
          ГЛАВНАЯ РАСПРОДАЖА ГОДА
        </h5>

        <p>
          Суперскидки на все курсы! Экономия до 60%
        </p>
      </div>

      <img
        src="/v2/global/black-friday24/sale.png"
        alt="60%"
        class="bf-banner__sale"
      >

      <div class="bf-banner__countdown">
        <p class="bf-banner__countdown--text">
          До конца акции:
        </p>

        <div class="bf-banner__countdown--items">
          <div
            v-for="item of countdown"
            :key="item.id"
            class="bf-banner__countdown--item"
          >
            <p>
              {{ item.value }}
            </p>
            <span>
              {{ item.text }}
            </span>
          </div>
        </div>
      </div>

      <button class="bf-banner__btn">
        забрать скидку
      </button>
    </div>
  </nuxt-link>
</template>

<script>
export default {
  name: 'BlackFriday24Banner',
  data() {
    return {
      now: new Date(),
      // targetDate: new Date('2024-12-01T00:00:00'),
    };
  },
  computed: {
    targetDate() {
      const today = new Date();

      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);

      return tomorrow;
    },
    countdown() {
      const diff = this.targetDate - this.now;
      const seconds = Math.floor((diff / 1000) % 60);
      const minutes = Math.floor((diff / 1000 / 60) % 60);
      const hours = Math.floor((diff / 1000 / 60 / 60) % 24);
      const days = Math.floor(diff / 1000 / 60 / 60 / 24);

      return [
        {
          id: 1,
          value: String(days).padStart(2, '0'),
          text: this.getDeclension(days, ['День', 'Дня', 'Дней']),
        },
        {
          id: 2,
          value: String(hours).padStart(2, '0'),
          text: this.getDeclension(hours, ['Час', 'Часа', 'Часов']),
        },
        {
          id: 3,
          value: String(minutes).padStart(2, '0'),
          text: this.getDeclension(minutes, ['Минута', 'Минуты', 'Минут']),
        },
        {
          id: 4,
          value: String(seconds).padStart(2, '0'),
          text: this.getDeclension(seconds, ['Секунда', 'Секунды', 'Секунд']),
        },
      ];
    },
  },
  mounted() {
    this.interval = setInterval(this.updateTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    getDeclension(number, words) {
      const cases = [2, 0, 1, 1, 1, 2];
      return words[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ];
    },
    updateTime() {
      this.now = new Date();
    },
  },
};
</script>

<style lang="scss" scoped>
.bf-banner {
  display: block;
  position: relative;
  height: 60px;
  overflow: hidden;

  &__bg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 60px;

    @include media-down(laptop) {
      display: none;
    }

    &--tablet {
      display: none;

      @include media-down(laptop) {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 50px;
      }

      @include media-down(tablet) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include media-down(tablet) {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 50px;
      }
    }
  }

  @include media-down(laptop) {
    height: 50px;
  }

  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 auto;

    @include media-down(laptop) {
      justify-content: flex-start;
    }
  }

  &__percent {
    display: block;
    height: 100%;
  }

  &__text {
    h5 {
      font-family: 'Advent Pro', sans-serif;
      font-size: 32px;
      font-weight: 800;
      line-height: 28.8px;
      letter-spacing: -0.02em;
      text-align: left;
      color: $white-color;

      @include media-down(laptop) {
        font-size: 24px;
        line-height: 21.6px;
      }

      @include media-down(tablet) {
        font-size: 20px;
        line-height: 18px;
      }
    }

    p {
      margin-top: 2px;

      font-family: 'Onest', sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 15.6px;
      text-align: left;
      color: $white-color;

      @include media-down(tablet) {
        font-size: 10px;
        line-height: 13px;
      }
    }
  }

  &__sale {
    display: block;
    height: 80%;
    margin-left: 2px;

    @include media-down(tablet) {
      height: 72%;
      margin-top: -1%;
    }
  }

  &__countdown {
    margin-left: 15px;

    @include media-down(tablet) {
      display: none;
    }

    &--text {
      padding-left: 12px;

      font-family: 'Jura', sans-serif;
      font-size: 12px;
      font-weight: 300;
      line-height: 110%;
      letter-spacing: -0.02em;
      color: #FF5319;
    }

    &--items {
      display: flex;
    }

    &--item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 52px;
      min-width: 52px;
      max-width: 52px;

      @include media-down(laptop) {
        width: 48px;
        min-width: 48px;
        max-width: 48px;
      }

      p {
        font-family: 'Jura', sans-serif;
        font-size: 24px;
        font-weight: 300;
        line-height: 90%;
        letter-spacing: -0.02em;
        text-align: center;
        color: #FF5319;
      }

      span {
        font-family: 'Jura', sans-serif;
        font-size: 12px;
        font-weight: 300;
        line-height: 70%;
        letter-spacing: -0.02em;
        text-align: center;
        color: #FF5319;

        @include media-down(laptop) {
          line-height: 100%;
        }
      }

      &:after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -0.5px;

        background-color: #FF5319;
      }

      &:last-of-type {
        &:after {
          content: none;
        }
      }
    }
  }

  &__btn {
    width: 188px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    border: none;
    border-radius: 16px;
    background-color: #FF5319;
    transform: rotate(-1deg);

    font-family: 'Onest', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    color: $white-color;

    cursor: pointer;

    @include media-down(laptop) {
      width: 100px;
      height: 30px;

      border-radius: 8px;
      transform: rotate(-2deg);

      font-size: 11px;
      line-height: 8.8px;
    }

    @include media-down(tablet) {
      display: none;
    }
  }
}
</style>
