<template>
  <div
    class="external-banner"
    tabindex="0"
    role="button"
    @click="openModal(MODAL_TYPES.externalBanner)"
  >
    <div class="external-banner__container">
      <p class="external-banner__text">
        скидка на&nbsp;экстернат <br> для&nbsp;детей и&nbsp;взрослых
      </p>
      <span class="external-banner__date">до {{ deadline }}</span>
      <span class="external-banner__60">-60%</span>
      <button
        class="m-btn"
        @click="openModal(MODAL_TYPES.externalBanner)"
      >
        зафиксируйте скидку
      </button>
      <ul>
        <li>любое количество классов за год</li>
        <li>уроки и&nbsp;аттестация в любое время</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { MODAL_TYPES } from '../common/AppHeader/data';

export default {
  name: 'ExternalBanner60',
  data: () => ({
    MODAL_TYPES,
  }),
  computed: {
    deadline() {
      const actualDate = new Date();
      const tomorrow = new Date(actualDate.setDate(actualDate.getDate() + 1));
      const day = tomorrow.getDate() < 10 ? `0${tomorrow.getDate()}` : tomorrow.getDate();
      const month = tomorrow.getMonth() + 1 < 10 ? `0${tomorrow.getMonth() + 1}` : tomorrow.getMonth() + 1;

      return `${day}.${month}`;
    },
  },
  methods: {
    ...mapActions('appHeaderModals', {
      openModal: 'openModal',
    }),
  },
};
</script>

<style lang="scss" scoped>
.external-banner {
  display: flex;
  height: calc(var(--scale) * 87);
  position: relative;

  background-image: url("/v2/global/external/bg.webp");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% calc(var(--scale) * 87);

  cursor: pointer;
  font-family: 'TildaSans', sans-serif;
  color: $white-color;

  @include media-down($size-tablet) {
    height: calc(var(--scale) * 50);
    background-size: cover;
    background-position: center;
    background-image: url("/v2/global/external/bg-tablet.webp");
  }

  @include media-down($size-mobile) {
    background-image: url("/v2/global/external/bg-mob.webp");
  }

  * {
    font-family: "Onest", sans-serif;
  }

  .external-banner__container {
    position: relative;
    padding-left: calc(var(--scale) * 350);
    width: 100%;
    display: flex;
    align-items: center;
    height: calc(var(--scale) * 60);
    margin-top: auto;
    overflow: hidden;

    @include media-down($size-tablet) {
      padding-left: calc(var(--scale) * 16);
      height: 100%;
      overflow: hidden;
    }

    @include media-down($size-mobile) {
      padding-left: calc(var(--scale) * 12);
      left: 0;
    }
  }

  .external-banner__60 {
    padding: calc(var(--scale) * 3) calc(var(--scale) * 8);
    position: absolute;
    left: calc(var(--scale) * 669);
    top: 0;
    background-color: #5237E6;
    border-radius: calc(var(--scale) * 44);
    transform: rotate(4deg);

    font-weight: 700;
    font-size: calc(var(--scale) * 20);
    line-height: 130%;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      padding: calc(var(--scale) * 2) calc(var(--scale) * 6);
      top: calc(var(--scale) * 3);
      left: calc(var(--scale) * 182);
      font-size: calc(var(--scale) * 15);
    }

    @include media-down($size-mobile) {
      top: calc(var(--scale) * 6);
      left: calc(var(--scale) * 199);
    }
  }

  .external-banner__date {
    padding: calc(var(--scale) * 5) calc(var(--scale) * 11);
    position: absolute;
    left: calc(var(--scale) * 690);
    bottom: 0;
    background-color: #E6CEFF;
    border-radius: calc(var(--scale) * 40);
    transform: rotate(-5deg);

    white-space: nowrap;
    font-weight: 700;
    font-size: calc(var(--scale) * 16);
    line-height: 130%;
    color: #5237E6;

    @include media-down($size-tablet) {
      padding: calc(var(--scale) * 4) calc(var(--scale) * 8);
      left: calc(var(--scale) * 197);
      bottom: calc(var(--scale) * 2);
      font-size: calc(var(--scale) * 12);
    }

    @include media-down($size-mobile) {
      left: calc(var(--scale) * 207);
      bottom: 0;
    }
  }

  .external-banner__text {
    width: calc(var(--scale) * 351);
    font-weight: 700;
    font-size: calc(var(--scale) * 32);
    line-height: 80%;
    letter-spacing: -0.02em;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      width: calc(var(--scale) * 187);
      font-size: calc(var(--scale) * 16);
    }

    @include media-down($size-mobile) {
      width: calc(var(--scale) * 196);
      font-size: calc(var(--scale) * 18);
      line-height: 90%;
    }
  }

  .m-btn {
    margin-left: calc(var(--scale) * 136);
    width: calc(var(--scale) * 277);
    height: calc(var(--scale) * 44);
    background-color: #FF5319;
    border-radius: calc(var(--scale) * 16);
    transform: rotate(-1deg);

    font-weight: 600;
    font-size: calc(var(--scale) * 22);
    line-height: 130%;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      margin-left: calc(var(--scale) * 74);
      width: calc(var(--scale) * 140);
      height: calc(var(--scale) * 30);
      border-radius: calc(var(--scale) * 8);
      transform: rotate(-2deg);
      font-size: calc(var(--scale) * 11);
      line-height: 80%;
    }

    @include media-down($size-mobile) {
      display: none;
    }

    &:hover {
      background-color: #FF9C12;
    }
  }

  ul {
    margin-left: calc(var(--scale) * 22);
    display: flex;
    flex-direction: column;
    gap: calc(var(--scale) * 2);

    @include media-down($size-tablet) {
      margin-left: calc(var(--scale) * 10);
      gap: 0;
    }

    @include media-down($size-mobile) {
      display: none;
    }

    li {
      display: flex;
      gap: calc(var(--scale) * 4);
      align-items: center;

      font-weight: 500;
      font-size: calc(var(--scale) * 14);
      line-height: 130%;
      color: #F6F6F6;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 12);
      }

      &:before {
        content: '';
        display: block;
        width: calc(var(--scale) * 14);
        height: calc(var(--scale) * 14);
        background-image: url("/v2/global/external/check.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}
</style>
